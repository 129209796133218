import React from "react"
import { graphql } from "gatsby"
// import SEO from "../components/seo"
import Layout from '../components/Layout';
import Post from '../components/Post';
import css from "./jupyter-template.module.css"
import InnerHTML from 'dangerously-set-html-content'
import { useSiteMetadata } from '../hooks';

const BlogPost = ({
  data: {
    jupyterNotebook: {
      html
    },
    markdownRemark
  },
}) => {
  console.log(html)
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const { frontmatter } = markdownRemark;
  const { title: postTitle, description: postDescription = '', socialImage } = frontmatter;
  const metaDescription = postDescription || siteSubtitle;
  const socialImageUrl = socialImage?.publicURL;
  markdownRemark['html'] = html;
  return (
    <Layout title={`${postTitle} - ${siteTitle}`} description={metaDescription} socialImage={socialImageUrl} >
      <div className={css['content']}>
        <Post post={markdownRemark} isJupyter={true} />
        {/* <div dangerouslySetInnerHTML={{ __html: html }} /> */}
      </div>
    </Layout>
  )
}
export default BlogPost
export const query = graphql`
  query BlogPostBySlug($slug: String!) {
    jupyterNotebook(fields: { slug: { eq: $slug } }) {
      html
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      fields {
        slug
        tagSlugs
      }
      frontmatter {
        date
        description
        tags
        title
        socialImage {
          publicURL
        }
      }
    }
  }
`